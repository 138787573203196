import { BaseObject } from '@shared/models/base-object.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { VisibilityScope } from '@shared/models/visibility-scope.model';
import { PartnerDictionary } from '@src/app/features/partner/models/partner-dictionary-model';
import * as moment from 'moment';
import { TreatmentType } from 'src/app/shared/components/treatment/treatment.enum';
import { MediaResource } from 'src/app/shared/models/media-resource.model';
import { FileInfo } from '../../media/models/media.model';
import { BaseTreatment } from '../../surgical/models/base-treatment.model';
import { Gender, MatchType } from '../patient.enum';
import { TooltipTemplate } from '@shared/modules/general-commons/components/tooltip/tooltip.model';
import {
  OnlinePatientReleaseForm,
  PatientReleaseFormAttachment
} from '@shared/components/patient-document-upload/patient-release-form.model';
import { NutritionType } from '@src/app/features/nutrition-management/enums/nutrition-type.enum';

export interface DictionaryTranslation {
  value: any;
  label: string;
  name?: string;
  tooltipText?: string | TooltipTemplate;
}

export interface PatientAddress {
  street1: string;
  city: string;
  countryId: number;
  country?: string;
  stateId: number;
  state?: string;
  districtId?: number;
  district?: string;
  zip: string;
  phone: string;
  mobile?: string;
}

export interface Diagnosis {
  previousSurgery: string;
  leftCleftLip: string;
  rightCleftLip: string;
  leftAlveolus: string;
  rightAlveolus: string;
  leftHardCleftPalate: string;
  rightHardCleftPalate: string;
  softCleftPalate: string;
  beforeSurgery: string;
  otherCleft: string;
  veloInsufficiency?: string;
  evaluationDate: moment.Moment;
  evaluationWeight: number;
  evaluationHeight: number;
  pregnancyComplication: string;
  birthComplication: string;
  motherSmoked: string;
  motherAlcohol: string;
  immedRelativeCleft: string;
  distRelativeCleft: string;
  pregnancyLength: number;
  heart: boolean;
  urinary: boolean;
  eyes: boolean;
  nose: boolean;
  ears: boolean;
  limbs: boolean;
  fingersToes: boolean;
  skin: boolean;
  tongue: boolean;
  skull: boolean;
  mandible: boolean;
  speech: boolean;
  growth: boolean;
  mental: boolean;
  allergies: string;
  medAllergies: string;
  otherAllergies: string;
  otherProblems: string;
  comment: string;
}

export type PatientId = number | string;

export interface PatientBase {
  id: PatientId;
  isReadonly: boolean;
  treatmentCenter: TreatmentCenterDictionary;
  partner: PartnerDictionary;
  treatmentCenterId: number;
  partnerId: number;
  recordNumberId: string;
  recordNumberIdLoc?: string;
  lastName: string;
  lastNameLoc?: string;
  middleName?: string;
  middleNameLoc?: string;
  firstName: string;
  firstNameLoc?: string;
  dateOfBirth: moment.Moment;
  dateOfBirthEstimated?: boolean;
  gender: string;
  status?: number;
  mediaResources?: MediaResource[];
  visibilityScope: VisibilityScope;
}

export interface Patient extends PatientBase, PatientReleaseFormAttachment {
  race: string;
  guardianFirstName: string;
  guardianLastName: string;
  guardianRelationship?: string;
  knowAboutSt?: string;
  referringOrg?: string;
  diagnosis?: Diagnosis;
  address: PatientAddress;
  file?: FileInfo;
  patientDetailsBlockPatient: PatientListItem;
  patientToCloneId: number;
}

export interface PatientListItem extends PatientBase {
  unlockable: boolean;
  deletable: boolean;
  patientTreatments: PatientTreatment[];
  patientIdForNewReleaseForm: number;
  patientIdForNewTreatment: number;
}

export interface ReleaseForms {
  onlineReleaseForm: OnlinePatientReleaseForm;
  offlineReleaseForm: FileInfo;
}

export interface PatientTreatment extends BaseObject {
  uniPatientId: number;
  treatmentCenterId: number;
  treatmentCenter: TreatmentCenterDictionary;
  partner: PartnerDictionary;
  partnerId: number;
  recordNumberId: string;
  recordNumberIdLoc: string;
  lastName: string;
  lastNameLoc?: string;
  middleName: string;
  middleNameLoc?: string;
  firstName: string;
  firstNameLoc?: string;
  dateOfBirth: moment.Moment;
  gender: Gender;
  status: number;
  treatmentId: number;
  treatmentType: TreatmentType;
  treatmentStatus: number;
  created: moment.Moment;
  modified: moment.Moment;
  submitted: moment.Moment;
  ageMonth: number;
  treatmentDate: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  treatmentTreatmentCenterId: number;
  surgeryOperationType: TreatmentsFullViewSurgeryOperationType;
  speechAssessmentData: TreatmentsFullViewSpeechAssessmentData;
  treatment: TreatmentData;
  deletable: boolean;
  unlockable: boolean;
  isReadonly: boolean;
}

export interface TreatmentData extends BaseTreatment {
  treatmentType: TreatmentType;
  ageMonth: number;
  treatmentDate: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  treatmentCenterId: number;
  surgeryOperationType: string[];
  speechAssessmentStage: string;
  nutritionManagementAgeGroup: NutritionType;
  isReadonly: boolean;
}

export type TreatmentItemData = TreatmentData & { patientId: number };

export interface TreatmentsFullViewSpeechAssessmentData {
  assessmentStage: number;
}

export interface TreatmentsFullViewSurgeryOperationType {
  alveolarBoneGraft: number;
  fistulaRepair: number;
  lipNoseRevision: number;
  primaryCleftPalate: number;
  primaryLipNoseBilateral: number;
  primaryLipNoseUnilateral: number;
  secondaryCleftPalate: number;
  velopharyngealDisfunction: number;
  other: number;
}

//TODO#43392 switch to business its
export interface MatchedPatient {
  patientId1: number;
  patientId2: number;
  matchType: MatchType;
}

export function patientIdAsNumber(patient: PatientId): number {
  if (typeof patient === 'number') {
    return patient;
  }
  // parse cn-1234 to 1234 and 1234 to 1234
  return parseInt(patient.replace('cn-', ''), 10);
}
