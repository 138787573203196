import { DisplayFileInfo, FileInfo } from '@/src/app/features/media/models/media.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { ButtonDisablerService } from '@/src/app/shared/services/button-disabler/button-disabler.service';
import { environment } from '@/src/environments/environment';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UppyRestrictionUtils, UppyUtils } from '@shared/components/uppy/uppy.utils';
import { SnackBarService } from '@shared/services/snack-bar.service';
import Uppy, { InternalMetadata, UppyFile } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { mapMimeToMediaContentType, Mime } from '@utils/file.utils';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { v4 } from 'uuid';
import { UppyErrorService } from '@shared/components/uppy/uppy-error.service';
import XHR from '@uppy/xhr-upload';

@Component({
  selector: 'stx-uppy',
  templateUrl: './uppy.component.html',
  styleUrls: ['./uppy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UppyComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() types: string[];
  @Input() height = 250;
  @Input() width = 600;
  @Input() hintNote;
  @Input() maxFileSizeInBytes: number | null = null;
  @Output() uploadSuccessful = new EventEmitter<FileInfo>();
  @Output() displayFile = new EventEmitter<DisplayFileInfo>();
  @Output() touched = new EventEmitter<void>();
  @Output() uploadCancel = new EventEmitter<void>();
  @Output() uploadError = new EventEmitter<void>();

  @Input() uuid: string = v4();
  currentFileInfo: FileInfo;
  uploadUrl: string;
  componentDestroyed = false;
  fileUploadInProgress = false;

  private uppy: Uppy;

  constructor(
    private globalErrorHandler: GlobalErrorHandlerService,
    private translateService: TranslateService,
    private buttonDisablerService: ButtonDisablerService,
    private snackBarService: SnackBarService,
    private uppyErrorService: UppyErrorService
  ) {
    super();
  }

  ngAfterViewInit() {
    this.setupUppyWithCurrentLang();
  }

  ngOnDestroy() {
    this.componentDestroyed = true;
    if (this.uppy) {
      this.uppy.close();
    }
    super.ngOnDestroy();
  }

  private setupUppyWithCurrentLang() {
    setTimeout(() => {
      this.subSink.sink = UppyUtils.getUppyCustomI18nObservable(this.translateService).subscribe(translations => {
        if (!this.uppy) {
          try {
            this.setUpUppyWithURL(translations);
          } catch (e) {
            if (!this.componentDestroyed) {
              throw e;
            }
          }
        }
      });
    });
  }

  addFileDragAndDropFile(file: File) {
    try {
      this.uppy.addFile({
        name: file.name,
        data: file,
        type: file.type
      });
    } catch (error) {
      if (UppyComponent.willErrorBeProcessedByRestrictionFailedEvenHandler(error)) {
        return;
      } else {
        throw error;
      }
    }
  }

  private static willErrorBeProcessedByRestrictionFailedEvenHandler(error: any): boolean {
    return error.hasOwnProperty('isRestriction') && !!error.isRestriction;
  }

  uploadResult(file: UppyFile<InternalMetadata, unknown>, response) {
    const mime = this.getMime(file.meta.type);
    const responseFromResourceServer: { fileName: string; fileId: string } = response.body;
    this.currentFileInfo = {
      fileName: responseFromResourceServer.fileName,
      fileId: responseFromResourceServer.fileId,
      mime
    };

    this.uploadSuccessful.emit(this.currentFileInfo);
    this.afterFileUpload();
    this.touched.emit();
    this.uppy.cancelAll();
  }

  onFileAdded(file: UppyFile): void {
    if (UppyUtils.isFileEmpty(file)) {
      this.informAboutEmptyFile();
      return;
    }

    const mime = this.getMime(file.meta.type);
    this.displayFile.emit({ file: file.data, mediaContentType: mapMimeToMediaContentType(mime) });
    this.fileUploadInProgress = true;
    this.buttonDisablerService.addTask();
    this.uppy.upload();
  }

  private informAboutEmptyFile(): void {
    this.uppy.cancelAll();
    this.uppyErrorService.setUploadedFileToEmptyBy(this.uuid);
    this.uploadError.emit();
  }

  private getMime(type: string): string {
    if (type.indexOf('video/') >= 0) {
      return Mime.VIDEO;
    } else if (type.indexOf('audio/') >= 0) {
      return Mime.AUDIO;
    } else {
      return type;
    }
  }

  getUploadParameters() {
    return {
      method: 'post',
      url: environment.mediaUploadUrl,
      fields: {},
      headers: {},
      timeout: 10 * 60 * 1000
    };
  }

  setUpUppyWithURL(translations: { [key: string]: string }): void {
    this.uppy = new Uppy({
      debug: !environment.production,
      autoProceed: false,
      restrictions: {
        maxFileSize: this.maxFileSizeInBytes,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: this.types
      }
    })
      .use(Dashboard, {
        inline: true,
        target: '.DashboardContainer-' + this.uuid,
        replaceTargetContent: false,
        width: this.width,
        height: this.height,
        thumbnailWidth: 280,
        showLinkToFileUploadResult: false,
        note: this.hintNote,
        proudlyDisplayPoweredByUppy: false,
        locale: UppyUtils.getUppyLocaleConfig(translations, this.translateService.currentLang)
      })
      .use(XHR, {
        endpoint: environment.mediaUploadUrl,
        fieldName: 'uploadFile',
        bundle: true,
        formData: true,
        limit: 1,
        headers: this.getUploadParameters().headers,
        method: 'post',
        timeout: this.getUploadParameters().timeout,
        responseType: 'text',
        withCredentials: true
      });

    this.uppy.on('file-added', file => this.onFileAdded(file));
    this.uppy.on('upload-success', this.uploadResult.bind(this));
    this.uppy.on('cancel-all', this.onCancelAll.bind(this));

    this.uppy.on('upload-error', (file, error, _response) => {
      if (this.checkIfTriggeredByUserCancelEvent(error)) {
        return;
      }
      this.onUploadError.bind(this);
    });

    this.uppy.on('error', error => {
      if (this.checkIfTriggeredByUserCancelEvent(error)) {
        return;
      }
      this.onError.bind(this);
    });
    this.uppy.on('restriction-failed', this.onRestrictionFailed.bind(this));
  }

  private onUploadError(_file: UppyFile<InternalMetadata, unknown>, error: unknown, _response: unknown) {
    this.globalErrorHandler.handleErrorAndInformUser(error);
    this.emitUploadCancelIfFileUploadInProgress();
    this.afterFileUpload();
    this.uppy.cancelAll();
    this.touched.emit();
  }

  private onCancelAll() {
    this.emitUploadCancelIfFileUploadInProgress();
    this.afterFileUpload();
  }

  private onError(error) {
    this.globalErrorHandler.handleErrorAndInformUser(error);
    this.touched.emit();
  }

  private onRestrictionFailed(_file: UppyFile<InternalMetadata, unknown>, error) {
    const errorTranslationKey = UppyRestrictionUtils.getTranslationKeyForErrorMessage(error.message);
    if (!errorTranslationKey) {
      this.globalErrorHandler.handleErrorAndInformUser(error);
    } else {
      this.snackBarService.showError({ title: errorTranslationKey });
    }
  }

  private emitUploadCancelIfFileUploadInProgress() {
    if (this.fileUploadInProgress) {
      this.uploadCancel.emit();
    }
  }

  private afterFileUpload() {
    if (this.fileUploadInProgress) {
      this.buttonDisablerService.removeTask();
      this.fileUploadInProgress = false;
    }
  }

  private checkIfTriggeredByUserCancelEvent(error): boolean {
    return error.message === 'Failed to upload' || error.message === 'Upload cancelled';
  }
}
